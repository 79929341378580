/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// slides.component.css
@import '~swiper/swiper-bundle';

// @import '~swiper/swiper-bundle.min';
// @import '~@ionic/angular/css/ionic-swiper';
// @import '~swiper/swiper';
// @import '~swiper/swiper-bundle.min';
// @import '~@ionic/angular/css/ionic-swiper';

// own Modifications
//header
ion-toolbar {
  --background: #3880ff;
}
ion-title, ion-back-button {
  color: white;
}

//buttons
ion-button {
  text-transform:initial;
}
.btnfillblue {
  --background-activated-opacity: 100%;
  --color-activated: white;
}

.backgroundgrey {
  --background: #f4f5f8;
}

.backgroundblack {
  --background: black;
}

.subtitlelight {
  color: black;
}

.subtitledark {
  color: white;
}

ion-segment {
  color: black;
  --background: #f4f5f8;
}

//select
.selectfullwidth {
  max-width: 100% !important;
}
.selectwiderwidth {
  max-width: 70% !important;
}

:root {
    .popover-content {
      left: 20px !important;
      width: 90%;
  }
  ion-popover ion-select-popover ion-radio-group ion-item ion-label
  {
      white-space: normal !important;
  }
}

// .native-input[disabled] {
//   opacity: 10 !important;
// }

//valid
.errorlabelmarginleft {
  margin-left: 20px;
}

.imgcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}


